<template>
  <div>
    <div class="bg-white dashboard-breadcrumb">
      <b-container class="pl-0">
        <div class="d-flex justify-content-between py-2">
          <div class="d-flex">
            <b-dropdown
              block
              right
              size="lg"
              :text="
                sb === 'SellerDashboard'
                  ? $t('dashboard.label.mnuSelling')
                  : $t('dashboard.label.mnuBuying')
              "
              menu-class="w-100"
              toggle-class="text-black"
              variant="white"
            >
              <b-dropdown-item @click="switchPage('SellerDashboard')">{{
                $t("dashboard.label.mnuSelling")
              }}</b-dropdown-item>
              <b-dropdown-item @click="switchPage('BuyerDashboard')">{{
                $t("dashboard.label.mnuBuying")
              }}</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="d-flex" v-if="sb === 'SellerDashboard'">
            <div class="my-auto">
              <TheSellButton class="sell-vehicle" />
            </div>
          </div>
        </div>
      </b-container>
    </div>

    <b-container class="px-0">
      <TheMenuSellerNavList v-if="sb === 'SellerDashboard'" />
      <TheMenuBuyerNavList v-if="sb === 'BuyerDashboard'" />
    </b-container>

    <b-modal id="activateBuyer" title="Activate Buyer Account" hide-footer centered>
      <template #default="{ hide }">
        <p class="m-4">Do you want activate Buyer Account</p>
        <section v-show="errorMessage">
          <b-alert variant="danger" show>{{ errorMessage }}</b-alert>
        </section>
        <b-row class="m-2">
          <b-col
            ><b-button variant="outline-danger" block @click="hide()"
              >Close</b-button
            ></b-col
          >
          <b-col
            ><b-button variant="outline-success" block @click="activate()"
              >Activate</b-button
            ></b-col
          >
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TheSellButton from "@/components/dashboard/global/TheSellAVehicleButton.vue";
import TheMenuSellerNavList from "@/components/dashboard/menus/TheMenuSellerNavList.vue";
import TheMenuBuyerNavList from "@/components/dashboard/menus/TheMenuBuyerNavList.vue";
import axios from "axios";

export default {
  components: {
    TheSellButton,
    TheMenuSellerNavList,
    TheMenuBuyerNavList,
  },
  data() {
    return {
      errorMessage: null,
    };
  },
  computed:{
    sb(){
      if (this.$store.getters.dashboardType) return this.$store.getters.dashboardType
      return "SellerDashboard"
    }
  },
  methods: {
    switchPage(pageName) {
      if (pageName === "BuyerDashboard") {
        if (this.$store.getters.user.Roles.includes("Buyer")){
          this.saveSB('BuyerDashboard')
        }
        else this.$bvModal.show("activateBuyer");
      } else {
        this.saveSB('SellerDashboard')
      }
    },
    saveSB(pageName) {
      this.$store.dispatch('setDashboardType', pageName)
      this.$router.push({ name: pageName });
    },
    async activate() {
      try {
        await axios.get("user/AddBuyerRole");
        await this.$store.dispatch("userFromApi", this.$store.getters.tokenID);
        this.$router.push({ name: "BuyerDashboard" });
        this.$bvModal.hide("activateBuyer");
      } catch (error) {
        this.errorMessage = error.response.data;
        console.log(error.response.data);
      }
      this.$bvModal.hide("activateBuyer");
    },
  },
  created() {
    this.switchPage(this.$route.name)
  },
  activated() {
    this.switchPage(this.$route.name)
  },
  beforeRouteEnter(from, to, next){
    next((vm) => {
      vm.switchPage(to.name)
    })
  },
  beforeRouteUpdate(from, to, next){
    next((vm) => {
      vm.switchPage(to.name)
    })
  },
};
</script>
<style lang="scss">
.cleanDrop .dropdown-item {
  padding: 0 !important;
}

.cleanDrop {
  background: white;
}
.cleanDropUserType {
  font-size: 1.1em;
}
.dashboard-breadcrumb {
  .dropdown-toggle::after {
    font-size: 17px;
    position: relative;
    left: 10px;
    bottom: -4px;
  }
}
.sell-vehicle {
  padding: 2px 20px;
  width: 188px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter" !important;
  svg {
    margin-right: 8px;
  }
}
</style>
