<template>
  <!-- this is dashboard Buyer menu -->
  <div>
    <div class="bg-white dashboard-breadcrumb">
      <b-container>
        <div class="my-3">
          <span style="font-size: 20px">
            {{ $t("dashboard.label.mnuBuying") }}
          </span>
        </div>
      </b-container>
    </div>

    <b-container class="px-0">
      <TheMenuBuyerNavList />
    </b-container>

    <!-- Begin:: Mobile Menu -->
    <div class="d-lg-none bg-secondary m-1">
      <b-dropdown
        size="lg"
        id="MobileMenu"
        :text="$t('dashboard.label.mnuBuying')"
        class="m-0 p-0 h5"
        menu-class="w-100 shadow-lg"
        block
      >
        <b-dropdown-item class="cleanDrop"><TheMenuBuyerList /></b-dropdown-item>
      </b-dropdown>
    </div>
    <!-- End:: Mobile Menu -->
  </div>
</template>

<script>
import TheMenuBuyerList from "@/components/dashboard/menus/TheMenuBuyerList.vue";
import TheMenuBuyerNavList from "@/components/dashboard/menus/TheMenuBuyerNavList.vue";
export default {
  components: {
    TheMenuBuyerNavList,
    TheMenuBuyerList,
  },
};
</script>

<style lang="scss">
.dashboard-breadcrumb {
  height: 64px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
</style>
