<template>
  <div>
    <div class="bg-light">
      <TheMenuBuyerNav v-if="buyer" />
      <TheMenuSellerNav v-else />
    </div>
    <div class="bg-light pb-4">
      <b-container class="mx-auto px-0">
        <router-view style="min-height:650px;" />
      </b-container>
    </div>

    <!-- <div class="container-fluid">
      <b-row class="row">

        <b-col class="p-0 col-lg-auto col-12">
          <TheMenuBuyer v-if="buyer" />
          <TheMenuSeller v-else />
        </b-col>

        <b-col class="px-4 mb-4 px-0 container-fluid">
          <router-view style="min-height:650px;"></router-view>
        </b-col>
      </b-row>
    </div> -->
  </div>
</template>

<script>
import TheMenuSellerNav from "@/components/dashboard/menus/TheMenuSellerNav.vue";
import TheMenuBuyerNav from "@/components/dashboard/menus/TheMenuBuyerNav.vue";
// import TheMenuSeller from "@/components/dashboard/menus/TheMenuSeller.vue";
// import TheMenuBuyer from "@/components/dashboard/menus/TheMenuBuyer.vue";

export default {
  components: {
    TheMenuSellerNav,
    TheMenuBuyerNav
    // TheMenuSeller,
    // TheMenuBuyer
  },
  data() {
    return {
      buyer: true
    };
  },
  beforeMount() {
    if (this.$store.getters.user.Roles.includes("Seller")) {
      this.buyer = false;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$store.getters.user || !localStorage.getItem("token")) {
        vm.$router.push({ name: "Home" });
        vm.$bvModal.show("signin");
      }
    });
  }
};
</script>

<style scoped></style>
